import screenAPI from '@/api/screens';
import { list } from './data';

export default {
  actions: {
    async getAllScreens({ commit }, project_id) {
      try {
        const screenList = await screenAPI.getAllScreens(project_id);
        commit('updatedScreenList', screenList);
      } catch (error) {
        // Handle error if needed
      }
    },
    async getScreen({ commit }, screen_id) {
      try {
        const screen = await screenAPI.getScreen(screen_id);
        commit('updateOnPageComponents', screen.nestedComponents ?? []);
        commit('addScreen', screen);
      } catch (error) {
        commit('updateOnPageComponents', []);
        // Handle error if needed
      }
    },
    async createScreen({ commit }, { project_id, data }) {
      try {
        const screen = await screenAPI.createScreen(project_id, data);
        commit('addToScreenList', screen);
      } catch (error) {
        // Handle error if needed
      }
    },
    async updateScreen({ commit }, { screen_id, data }) {
      try {
        const updatedData = await screenAPI.updateScreen(screen_id, data);
        commit('addScreen', updatedData);
        if (updatedData.nestedComponents) {
          commit('updateOnPageComponents', updatedData.nestedComponents);
        }
        commit('updatedScreenInList', { screen_id, updatedData });
      } catch (error) {
        // Handle error if needed
      }
    },
    async updateAndBroadcastScreen({ commit }, { screen_id, data }) {
      try {
        const updatedData = await screenAPI.updateAndBroadcastScreen(
          screen_id,
          data
        );
        if (updatedData.nestedComponents) {
          commit('updateOnPageComponents', updatedData.nestedComponents);
        }
      } catch (error) {
        // Handle error if needed
      }
    },
    async deleteScreen({ commit }, screen_id) {
      try {
        await screenAPI.deleteScreen(screen_id);
        commit('removeFromScreenList', screen_id);
      } catch (error) {
        // Handle error if needed
      }
    },
  },
  mutations: {
    updateComponents(state, components) {
      state.components = components;
    },
    updateOnPageComponents(state, components) {
      state.onPageComponents = components;
    },
    updatedCurrentConfiguredWidget(state, components) {
      state.currentConfiguredWidget = components;
    },
    updatedScreenList(state, screenList) {
      state.screenList = screenList;
    },
    updatedScreenInList(state, { screen_id, updatedData }) {
      const index = state.screenList.findIndex(
        (screen) => screen.id === screen_id
      );
      if (index !== -1) {
        // Ensure to maintain the same reference if you want to keep reactivity
        state.screenList.splice(index, 1, {
          ...state.screenList[index],
          ...updatedData,
        });
      }
    },
    addToScreenList(state, screen) {
      state.screenList.push(screen);
    },
    removeFromScreenList(state, screen_id) {
      state.screenList = state.screenList.filter((x) => x.id !== screen_id);
    },
    addScreen(state, screen) {
      state.screens = { ...state.screens, [screen.id]: screen };
    },
  },
  state: {
    components: list,
    currentConfiguredWidget: '',
    screens: {},
    screenList: [],
    onPageComponents: [],
  },
  getters: {
    allComponents(state) {
      return state.components;
    },
    onPageComponents(state) {
      return state.onPageComponents;
    },
    currentConfiguredWidget(state) {
      return state.currentConfiguredWidget;
    },
    screenList(state) {
      return state.screenList;
    },
    screens(state) {
      return state.screens;
    },
  },
};
